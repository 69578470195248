
/*
|--------------------------------------------------------------------------
| Dependency Definitions
|--------------------------------------------------------------------------
*/

require('./../bootstrap');

import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPen, faTrash, faCheckCircle, faBan, faBars, faClone, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faPen, faTrash, faCheckCircle, faBan, faBars, faClone, faSearch);

Vue.use(BootstrapVue);



/*
|--------------------------------------------------------------------------
| Global Components
|--------------------------------------------------------------------------
*/

Vue.component('application', require('./components/Application').default);
Vue.component('font-awesome-icon', FontAwesomeIcon);



/*
|--------------------------------------------------------------------------
| Application
|--------------------------------------------------------------------------
*/

let app = new Vue({
  el: '#app'
});
