<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';

  import axios from 'axios';

  import Swal from 'sweetalert2';



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'attachment-row',

    props: [
      'attachment',
      'rowNo'
    ],

    data() {
      return {
        currentAttachment: null
      }
    },

    computed: {
      isCompleted() {
        return (this.attachment.application_id != null);
      },

      attachmentFilename() {
        return this.attachment.filename;

        //return '/applications/application_' + this.attachment.application_id + '/' + this.attachment.filename;
      }
    },

    methods: {
      removeAttachment() {
        Swal.fire({
          title: 'Ta bort bilaga?',
          text: "Bilagan kommer att tas bort från ansökan.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Ta bort bilaga'
        }).then((result) => {
          if (result.value) {
            this.$emit('removeAttachment', this.attachment.id);
          }
        })
      },

      updateAttachment() {
        this.$emit('updateAttachment', this.attachment.id, this.currentAttachment);
      }
    }
  };
</script>

<template>
  <b-row class="mt-2">
    <b-col cols="1">
      <h2>{{ rowNo + 1 }}</h2>
    </b-col>

    <b-col cols="9" v-if="!isCompleted">
      <b-form-file
        v-model="currentAttachment"
        browse-text="Välj bilaga"
        placeholder="Klicka på knappen eller dra en bilaga hit"
        drop-placeholder="Dra en bilaga hit"
        accept="application/pdf"
        @input="updateAttachment"
      ></b-form-file>
    </b-col>

    <b-col cols="9" v-if="isCompleted">
      <a :href="attachmentFilename" target="_blank" v-if="attachmentFilename">
        <svg class="artwork-icon noselect mb-2" xmlns="http://www.w3.org/2000/svg" width="32.24" height="37" viewBox="0 0 32.24 37">
          <g transform="translate(-253.844 -250.005)">
            <path d="M297.636,258.266v26.821A1.919,1.919,0,0,1,295.717,287H271.3a1.919,1.919,0,0,1-1.919-1.919V251.924a1.919,1.919,0,0,1,1.919-1.919h18.865a1.917,1.917,0,0,1,1.442.656L297.162,257A1.925,1.925,0,0,1,297.636,258.266Z" transform="translate(-11.551)" fill="#fff"/>
            <path d="M297.162,257l-5.553-6.342a1.917,1.917,0,0,0-1.442-.656H271.3a1.919,1.919,0,0,0-1.919,1.919v33.163A1.919,1.919,0,0,0,271.3,287h24.416a1.919,1.919,0,0,0,1.919-1.919V258.266A1.925,1.925,0,0,0,297.162,257Zm-6.963-5.04,5.371,6.133H290.2Zm5.519,33.124H271.3V251.924H288.28V258.1a1.919,1.919,0,0,0,1.919,1.919h5.519Z" transform="translate(-11.551)" fill="#333"/>
            <rect width="19.185" height="8.93" rx="4.465" transform="translate(253.844 256.72)" fill="#fc2130"/>
            <path d="M.982,0H16.467a.982.982,0,0,1,.982.982v0a.982.982,0,0,1-.982.982H.982A.982.982,0,0,1,0,.982v0A.982.982,0,0,1,.982,0Z" transform="translate(263.233 268.506)" fill="#333"/>
            <path d="M.982,0H16.467a.982.982,0,0,1,.982.982v0a.982.982,0,0,1-.982.982H.982A.982.982,0,0,1,0,.982v0A.982.982,0,0,1,.982,0Z" transform="translate(263.233 272.685)" fill="#333"/>
            <g transform="translate(257.11 258.597)">
              <path d="M268.7,283.489c1.071,0,1.816.5,1.816,1.593a1.546,1.546,0,0,1-1.76,1.579h-1.113v1.781h-1.078v-4.954Zm-1.057,2.317h.967c.487,0,.849-.16.849-.71,0-.529-.362-.716-.842-.716h-.974Z" transform="translate(-266.569 -283.489)" fill="#fff"/>
              <path d="M284.659,283.489h2a2.478,2.478,0,0,1,0,4.954h-2Zm1.871,4.049c1,0,1.44-.578,1.44-1.572s-.459-1.573-1.44-1.573h-.793v3.145Z" transform="translate(-280.017 -283.489)" fill="#fff"/>
              <path d="M308.221,284.4h-2.407v1.148h2.094v.863h-2.094v2.032h-1.085v-4.954h3.493Z" transform="translate(-294.936 -283.489)" fill="#fff"/>
            </g>
            <path d="M.982,0H16.467a.982.982,0,0,1,.982.982v0a.982.982,0,0,1-.982.982H.982A.982.982,0,0,1,0,.982v0A.982.982,0,0,1,.982,0Z" transform="translate(263.233 276.879)" fill="#333"/>
          </g>
        </svg>
          Bilaga {{ rowNo + 1 }}
        </a>
    </b-col>

    <b-col cols="2">
      <b-button class="mr-1" variant="danger" @click="removeAttachment">
        <font-awesome-icon icon="trash" />
      </b-button>
    </b-col>
  </b-row>
</template>
